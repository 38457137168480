export const brandGuide = {
  brandIdentity: {
    name: "Identidade da Marca",
    value: "brandIdentity",
    form: [
      {
        label: "Qual é o nome da empresa?",
        value: "companyName",
        placeholder: "Digite o nome da empresa",
        type: "text",
      },
      {
        label: "A empresa possui algum Slogan?",
        value: "slogan",
        placeholder: "Digite o slogan da empresa",
        type: "text",
      },
      {
        label: "Qual o propósito, por que existem?",
        value: "purpose",
        placeholder: "Digite o propósito da marca",
        type: "textarea",
      },
      {
        label: "Qual é a missão da marca?",
        value: "mission",
        placeholder: "Digite a missão da marca",
        type: "textarea",
      },
      {
        label: "Qual a visão da marca?",
        value: "vision",
        placeholder: "Digite a visão da marca",
        type: "textarea",
      },
      {
        label:
          "Quais são os valores centrais (ex. inovação, sustentabilidade, qualidade etc)?",
        value: "coreValues",
        placeholder: "Digite os valores centrais da marca",
        type: "textarea",
      },
    ],
  },
  brandPersonality: {
    name: "Personalidade da Marca",
    value: "brandPersonality",
    form: [
      {
        label:
          "Se fosse uma pessoa, como a descreveria (amigável, divertida, profissional, confiável, humana etc)?",
        value: "brandDescription",
        placeholder: "Digite a descrição da marca como se fosse uma pessoa",
        type: "textarea",
      },
      {
        label: "Quais são as três palavras que melhor descrevem a marca?",
        value: "brandWords",
        placeholder: "Digite as três palavras que melhor descrevem a marca",
        type: "textarea",
      },
      {
        label:
          "Que emoções você quer que os consumidores sintam ao interagir com a marca?",
        value: "brandEmotions",
        placeholder: "Digite as emoções que quer que os consumidores sintam",
        type: "textarea",
      },
    ],
  },
  valueProposition: {
    name: "Proposta de Valor",
    value: "valueProposition",
    form: [
      {
        label: "O que torna a marca única no mercado?",
        value: "uniqueSellingPoint",
        placeholder: "Digite o que torna a marca única no mercado",
        type: "textarea",
      },
      {
        label:
          "Quais são os principais benefícios dos seus produtos ou serviços?",
        value: "benefits",
        placeholder:
          "Digite os principais benefícios dos seus produtos ou serviços",
        type: "textarea",
      },
      {
        label: "Como a marca se diferencia dos concorrentes?",
        value: "differentiation",
        placeholder: "Digite como a marca se diferencia dos concorrentes",
        type: "textarea",
      },
    ],
  },
  brandCommunication: {
    name: "Comunicação da Marca",
    value: "brandCommunication",
    form: [
      {
        label:
          "Qual o tom de voz (ex. formal, engraçado, inspirador, casual etc)?",
        value: "toneOfVoice",
        placeholder: "Digite o tom de voz da Empresa",
        type: "text",
      },
      {
        label: "Quais são as mensagens-chave da sua marca?",
        value: "keyMessages",
        placeholder: "Digite as mensagens-chave da Empresa",
        type: "textarea",
      },
      {
        label: "Como defende cada uma das mensagens?",
        value: "messageDefense",
        placeholder: "Digite como a marca defende cada uma das mensagens",
        type: "textarea",
      },
      {
        label: "Palavras e Expressões Utilizadas",
        value: "usedWords",
        placeholder: "Digite as palavras e expressões que a marca utiliza",
        type: "textarea",
      },
      {
        label: "Palavras e expressões que não devem ser mencionadas",
        value: "forbiddenWords",
        placeholder:
          "Digite as palavras e expressões que não devem ser mencionadas",
        type: "textarea",
      },
    ],
  },
  brandPositioning: {
    name: "Posicionamento de Marca",
    value: "brandPositioning",
    form: [
      {
        label: "Qual é o mercado alvo (local, nacional, global)?",
        value: "targetMarket",
        placeholder: "Digite o mercado alvo da Empresa",
        type: "text",
      },
      {
        label: "Como gostaria que sua marca fosse percebida?",
        value: "brandPerception",
        placeholder: "Digite como gostaria que sua marca fosse percebida",
        type: "textarea",
      },
    ],
  },
  brandImpact: {
    name: "Impacto da Marca",
    value: "brandImpact",
    form: [
      {
        label: "Quais são as práticas sustentáveis da empresa?",
        value: "sustainablePractices",
        placeholder: "Digite as práticas sustentáveis da empresa",
        type: "textarea",
      },
      {
        label:
          "Quais são as práticas de diversidade, inclusão, equidade e pertencimento?",
        value: "diversityInclusionEquityPertinencePractices",
        placeholder:
          "Digite as práticas de diversidade, inclusão, equidade e pertencimento",
        type: "textarea",
      },
      {
        label: "Como contribuem com a comunidade e causas sociais?",
        value: "communityAndSocialCausesContribution",
        placeholder: "Digite como contribuem com a comunidade e causas sociais",
        type: "textarea",
      },
    ],
  },
  seo: {
    name: "SEO",
    value: "seo",
    form: [
      {
        label:
          "Quais palavras e expressões chave que devem ser utilizadas para otimizar o SEO?",
        value: "keywords",
        placeholder: "Digite as palavras-chave para otimização de SEO",
        type: "textarea",
      },
    ],
  },
  targetAudiences: {
    name: "Públicos Alvo",
    value: "targetAudiences",
    registerMultiples: true,
    form: [
      {
        label: "Nome do Público Alvo",
        value: "name",
        placeholder: "Atribua um nome a esse Público Alvo",
        type: "text",
      },
      {
        label: "Idade",
        value: "age",
        placeholder: "Digite a faixa etária do público-alvo",
        type: "text",
      },
      {
        label: "Orientação sexual",
        value: "sexualOrientation",
        placeholder: "Digite a orientação sexual do público-alvo",
        type: "text",
      },
      {
        label: "Localização",
        value: "location",
        placeholder: "Digite a localização do público-alvo",
        type: "text",
      },
      {
        label: "Interesses",
        value: "interests",
        placeholder: "Digite os interesses do público-alvo",
        type: "text",
      },
      {
        label: "Necessidades e desejos",
        value: "needsDesires",
        placeholder: "Digite as necessidades e desejos do público-alvo",
        type: "text",
      },
      {
        label: "Quais problemas a marca resolve desse público?",
        value: "brandProblems",
        placeholder:
          "Digite os problemas que a marca resolve para o público-alvo",
        type: "text",
      },
      {
        label: "Como seu público se comunica?",
        value: "communicationStyle",
        placeholder: "Digite como o público-alvo se comunica",
        type: "text",
      },
      {
        label: "Onde passa o tempo online?",
        value: "onlinePresence",
        placeholder: "Digite onde o público-alvo passa o tempo online",
        type: "text",
      },
    ],
  },
  sections: {
    name: "Editorias",
    value: "sections",
    registerMultiples: true,
    form: [
      {
        label: "Nome da Editoria",
        value: "name",
        placeholder: "Atribua um nome a essa Editoria",
        type: "text",
      },
      {
        label: "Descrição",
        value: "description",
        placeholder: "Descreva a Editoria",
        type: "textarea",
      },
    ],
  },
  spokespeople: {
    name: "Porta Vozes",
    value: "spokespeople",
    registerMultiples: true,
    form: [
      {
        label: "Nome do Porta-Voz",
        value: "name",
        placeholder: "Atribua um nome a esse Porta-Voz",
        type: "text",
      },
      {
        label: "Qual é seu cargo?",
        value: "role",
        placeholder: "Qual o cargo do Porta-Voz",
        type: "text",
      },
      {
        label: "Quais são seus Mandatos? Sobre quais assuntos pode falar?",
        value: "mandate",
        placeholder: "Digite os Mandatos do Porta-Voz",
        type: "textarea",
      },
    ],
  },
  products: {
    name: "Produtos ou Serviços",
    value: "products",
    registerMultiples: true,
    form: [
      {
        label: "Nome do Produto ou Serviço",
        value: "name",
        placeholder: "Atribua um nome a esse Produto ou Serviço",
        type: "text",
      },
      {
        label: "Descrição",
        value: "description",
        placeholder: "Descreva o Produto ou Serviço",
        type: "textarea",
      },
      {
        label: "Quais são os diferenciais do produto ou serviço?",
        value: "differentials",
        placeholder: "Descreva os diferenciais do Produto ou Serviço",
        type: "textarea",
      },
    ],
  },
  textExamples: {
    name: "Exemplos de Textos",
    value: "textExamples",
    registerMultiples: true,
    form: [
      {
        label: "Nome do Texto",
        value: "name",
        placeholder: "Atribua um nome a esse Texto",
        type: "text",
      },
      {
        label: "Aonde esse texto foi publicado?",
        value: "channel",
        placeholder: "Descreva o Texto",
        type: "textarea",
      },
      {
        label: "Texto",
        value: "text",
        placeholder: "Digite o Texto",
        type: "textarea",
      },
    ],
  },
};
