import userSession from "./userSession";
import { toast } from "react-toastify";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080/api"
    : process.env.REACT_APP_API_URL || "http://localhost:8080/api";

export const COPY_AGENT_BASE_URL =
  "https://hera-agents-back-prod-3xoekxrqfq-uw.a.run.app/copywriter";

console.log("NODE ENV", process.env.NODE_ENV);
console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL);
console.log("COPY_AGENT_BASE_URL", COPY_AGENT_BASE_URL);

//export const COPY_AGENT_BASE_URL =
//  process.env.NODE_ENV === "development"
//    ? "http://localhost:8080/copywriter"
//    : process.env.COPY_AGENT_URL || "http://localhost:8080/copywriter";

export const httpServiceGet = async ({ url, ...props }) => {
  Object.entries(props).forEach(([key, value]) => {
    if (typeof value !== "number" && !value?.length) return;
    url.searchParams.append(key, value);
  });

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userSession.getToken()}`,
    },
  });

  await checkApiResponse(response);

  const data = await response.json();

  return data;
};

export const httpServiceCreate = async (
  url,
  payload,
  type,
  showToast = true,
) => {
  if (!type) {
    type = payloadTypes.JSON;
  }

  const headers = {
    Authorization: `Bearer ${userSession.getToken()}`,
  };

  if (type === payloadTypes.JSON) {
    headers["Content-Type"] = type;
  }

  const body = type === payloadTypes.JSON ? JSON.stringify(payload) : payload;

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: body,
  });

  await checkApiResponse(response);

  const data = await response.json();

  if (!data) {
    throw new Error();
  }

  if (showToast) {
    createdSuccessfullyToast();
  }

  return data;
};

export const httpServiceUpdate = async (
  url,
  payload,
  type,
  showToast = true,
) => {
  if (!type) {
    type = payloadTypes.JSON;
  }

  const headers = {
    Authorization: `Bearer ${userSession.getToken()}`,
  };

  if (type === payloadTypes.JSON) {
    headers["Content-Type"] = type;
  }

  const body = type === payloadTypes.JSON ? JSON.stringify(payload) : payload;

  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body: body,
  });

  await checkApiResponse(response);

  const data = await response.json();

  if (!data) {
    throw new Error();
  }

  if (showToast) {
    updatedSuccessfullyToast();
  }

  return data;
};

export const payloadTypes = {
  JSON: "application/json",
  FORM_DATA: "multipart/form-data",
};

export const httpServiceDelete = async (url) => {
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userSession.getToken()}`,
    },
  });

  await checkApiResponse(response);

  deletedSuccessfullyToast();

  return;
};

export const checkApiResponse = async (response) => {
  if (!response.ok) {
    const data = await response.json();
    const error = new Error();
    error.status = response.status;
    error.msg = data?.detail || "";
    throw error;
  }
};

export const apiDefaultErrorHandler = (error) => {
  if (error.status === 401) {
    toast.error("Sessão expirada, faça login novamente.");
    userSession.removeSession();
    setTimeout(() => {
      window.location.reload();
    }, 1500);

    return;
  }
  if (error.status === 403) {
    toast.error("Você não tem permissão para acessar este recurso.");
    return;
  }
  if (error.status === 400) {
    toast.error("Recurso não encontrado.");
    return;
  }
  if (error.status === 404) {
    toast.error("Não encontrado.");
    return;
  }
  if (error.status === 409) {
    toast.error("Ocorreu um erro: " + error.msg);
    return;
  }
  if (error.status === 422) {
    toast.error(
      "Requisição inválida, verifique os dados informados. ",
      error.msg,
    );
    return;
  }
  if (error.status === 500) {
    toast.error("Erro interno no servidor, tente novamente mais tarde.");
    return;
  }
  toast.error("Erro inesperado, tente novamente mais tarde.");
};

export const createdSuccessfullyToast = () => {
  toast.success("Criado com sucesso!");
};

export const updatedSuccessfullyToast = () => {
  toast.success("Atualizado com sucesso!");
};

export const deletedSuccessfullyToast = () => {
  toast.success("Deletado com sucesso!");
};

export const customSuccessToast = (message) => {
  toast.success(message);
};
